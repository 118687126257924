import { supportedCurrencyLocaleInfo } from "./constants"

function formatPrice(price, currency, roundUp) {
  let localeInfo = mapCurrencyCodeToLocale(currency)
  price = roundUp ? Math.ceil(price) : price
  return new Intl.NumberFormat(localeInfo.locale, {
    style: 'currency',
    currency: localeInfo.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  })
    .format(price)
    .replace(/\D00(?=\D*$)/, '')
}

function formatEventPrice(price) {
  return Math.round(price)
}

function mapCurrencyCodeToLocale(currency) {
  const localeInfo = supportedCurrencyLocaleInfo[currency.toUpperCase()];
  return localeInfo ? localeInfo : supportedCurrencyLocaleInfo.USD
}

export { formatEventPrice, formatPrice }
