const paymentOptionsTitlesEnum = Object.freeze({
  SINGLE_PAY: "Single Pay",
  SMILE_PAY: "SmilePay",
  PURCHASE_LATER: "Not purchasing yet"
});

const singlePayPaymentDescriptions = ['Easy one-time payment.', 'Biggest savings.']
const purchaseLaterPaymentDescriptions = ['Purchase aligners later.']
const smilePayPaymentDescriptions =
    [
    '{downPayment} {downPaymentTerm}, {monthly-price}/month or less for {term} months ({total-price} total).<sup>1<sup>',
        'Convenient monthly payments.',
        'No credit checks. No forms to fill out. 100% approval.',

    ]

const paymentOptionsEnum = Object.freeze({
  SINGLE_PAY: "singlepay",
  SMILE_PAY: "smilepay",
});

const countryCodesEnum = Object.freeze({
    AUSTRALIA: "AU",
    UNITED_STATES: "US",
});

const supportedCurrencyLocaleInfo = Object.freeze({
  USD: { locale: "en-us", currency: "USD"},
  AUD: { locale: "en-au", currency: "AUD"},
  CAD: { locale: "en-ca", currency: "CAD"},
});

const errorCodes = Object.freeze({
  RETRIEVING_PRODUCT_CREDIT_TERMS_FAILED: 'RetrievingProductCreditTermsFailed',
  RETRIEVING_FINANCING_TERMS_FAILED: 'RetrievingProductCreditTermsFailed',
});

const ENVIRONMENTS = Object.freeze({
    QA: 'qa',
    STAGE: 'stage',
    PROD: 'prod',
});
const ENV = process.env.VUE_APP_ENV || ENVIRONMENTS.QA;

export {
  paymentOptionsTitlesEnum,
  paymentOptionsEnum,
  countryCodesEnum,
  singlePayPaymentDescriptions,
  smilePayPaymentDescriptions,
  purchaseLaterPaymentDescriptions,
  supportedCurrencyLocaleInfo,
  errorCodes,
  ENVIRONMENTS,
  ENV
}
