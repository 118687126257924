<template>
  <div
    id="smilepay-ui-container"
    data-orion-id="smilepay-ui-container"
    class="container"
    v-if="showComponent"
  >
    <smile-loading :active="isLoading" data-orion-id="smile-loading"/>
    <PaymentOption
      v-for="cft in combinedFinanceTerms"
      :title="paymentOptionsTitlesEnum.SMILE_PAY"
      :financeTerm="cft.term"
      :discountedFinanceTerm="cft.discountedTerm"
      :key="getSmilePayKey(cft.term.months)"
      :price="financingPrice"
      :productSKU="productSKU"
      :currency="currency"
      :country="country"
      :region="region"
      :targetDomain="targetDomain"
      :discount="discount"
      :paymentDescriptions="getSmilePayDescription(cft.discountedTerm ? cft.discountedTerm : cft.term)"
      :selected="isSmilePaySelected(selectedOption, cft.term.months)" 
      @payment-option-selected="paymentOptionSelectedChanged"
      @mounted="optionMounted(getSmilePayKey(cft.term.months))"
      ref="smilepay"
    />
    <PaymentOption
      v-if="!isLoading"
      :title="paymentOptionsTitlesEnum.SINGLE_PAY"
      key="singlepay"
      :price="price"
      :productSKU="productSKU"
      :discountedFinanceTerm="combinedFinanceTerms[0]?.discountedTerm"
      :currency="currency"
      :country="country"
      :discount="discount"
      :region="region"
      :targetDomain="targetDomain"
      :paymentDescriptions="singlePayPaymentDescriptions"
      :selected="'singlepay' === selectedOption"
      @payment-option-selected="paymentOptionSelectedChanged"
      @mounted="optionMounted('singlepay')"
      ref="singlepay"
    />
    <PaymentOption
      v-if="showDeferredPayment"
      :title="paymentOptionsTitlesEnum.PURCHASE_LATER"
      key="notPurchasing"
      :price="price"
      :productSKU="productSKU"
      :currency="currency"
      :country="country"
      :region="region"
      :targetDomain="targetDomain"
      :paymentDescriptions="purchaseLaterPaymentDescriptions"
      :selected="'notPurchasing' === selectedOption"
      @payment-option-selected="paymentOptionSelectedChanged"
      ref="notPurchasing"
      @mounted="optionMounted('notPurchasing')"
    />
  </div>
</template>

<script>
import PaymentOption from '@/components/PaymentOption.vue'
import {
  paymentOptionsTitlesEnum,
  paymentOptionsEnum,
  singlePayPaymentDescriptions,
  smilePayPaymentDescriptions,
  purchaseLaterPaymentDescriptions,
  countryCodesEnum,
  errorCodes,
  ENV,
} from '@/utils/constants'
import { TermsServiceClient } from '@/utils/terms-service-api'
import { FinanceClient } from '@smiledirectclub/sdc.pfi-core-external'
import { SmileLoading } from 'sdc-component-library'
import { formatPrice } from '@/utils/formatter'
import { datadogLogs } from '@datadog/browser-logs'

export default {
  name: 'SmilePayUI',
  components: { PaymentOption, SmileLoading },
  props: {
    price: {
      required: true,
      type: Number,
    },
    productSKU: {
      required: true,
      type: String,
    },
    financingPrice: {
      required: true,
      type: Number,
    },
    financingProductSKU: {
      required: true,
      type: String,
    },
    currency: {
      required: true,
      type: String,
    },
    country: {
      required: true,
      type: String,
    },
    region: {
      required: false,
      type: String,
      default: 'CA',
    },
    showDeferredPayment: {
      required: false,
      type: Boolean,
      default: false,
    },
    targetDomain: String,
    env: {
      type: String,
      default: ENV,
    },
    defaultOption: {
      type: String,
      default: '',
    },
    discount: {
      required: false,
      type: Number,
    },
    defaultSubOption: String,
  },
  data() {
    return {
      singlePayPaymentDescriptions,
      purchaseLaterPaymentDescriptions,
      paymentOptionsTitlesEnum,
      selectedOption: this.defaultSubOption
        ? `${this.defaultOption}-${this.defaultSubOption}`
        : this.defaultOption,
      showComponent: false,
      errors: [],
      creditTerms: [],
      financeTerms: [],
      discountedFinanceTerms: [],
      combinedFinanceTerms: [],
      isLoading: true,
    }
  },
  beforeMount() {
    this.showComponent =
      Number.isInteger(this.price) &&
      typeof this.productSKU !== 'undefined' &&
      Number.isInteger(this.financingPrice) &&
      typeof this.financingProductSKU !== 'undefined' &&
      typeof this.currency !== 'undefined' &&
      typeof this.country !== 'undefined'

    this.initializeComponent()
    window.addEventListener('message', function(event) {
    if (event.data.eventType === 'SelectedPaymentPlanEvent')
      console.log(event)
    });
  },
  methods: {
    optionMounted(key){
      if (this.selectedOption && this.$refs[this.selectedOption]){
        if(this.selectedOption === paymentOptionsEnum.SMILE_PAY && key === paymentOptionsEnum.SMILE_PAY) {
          this.$refs[this.selectedOption][0].selectedPaymentPlan()
        }
        else if (this.selectedOption === paymentOptionsEnum.SINGLE_PAY && key === paymentOptionsEnum.SINGLE_PAY)
        {
          this.$refs[this.selectedOption].selectedPaymentPlan()
        }
      }
    },
    paymentOptionSelectedChanged(option) {
      this.selectedOption = option
    },
    isSingleTermSmilePay() {
      return this.combinedFinanceTerms.length === 1
    },
    isSmilePaySelected(selectedOption, months) {
      return this.isSingleTermSmilePay() ? selectedOption === paymentOptionsEnum.SMILE_PAY : selectedOption === `${paymentOptionsEnum.SMILE_PAY}-${months}`
    },
    getSmilePayKey(months) {
      return this.isSingleTermSmilePay() ? `${paymentOptionsEnum.SMILE_PAY}` : `${paymentOptionsEnum.SMILE_PAY}-${months}`
    },
    getSmilePayDescription(financeTerm) {
      const {
        months,
        monthly_payment,
        total_payment,
        credit_product: { down_payment },
      } = financeTerm

      const description = smilePayPaymentDescriptions.map((x) =>
        x
          .replace('{downPayment}', formatPrice(down_payment, this.currency))
          .replace('{downPaymentTerm}', this.downPaymentTerm)
          .replace('{term}', months)
          .replace('{monthly-price}', formatPrice(monthly_payment, this.currency, true))
          .replace('{total-price}', formatPrice(total_payment + down_payment, this.currency))
      )
      return description
    },
    async getFinanceTerms(discountAmount = 0) {
      var location = this.country == countryCodesEnum.UNITED_STATES ? this.region : this.country
      for (const ct of this.creditTerms) {
        const fsRequest = {
          location: location,
          amount: this.financingPrice - ct.downPayment - discountAmount,
          months: ct.term,
          businessEntity: this.country,
        }

        try {
          const terms = await this.fsClient.getFinanceTerms(fsRequest)
          return terms[0]
        } catch (error) {
          datadogLogs.logger.error(`Error: ${error}`, {
            errorDetails: { errorCode: errorCodes.RETRIEVING_FINANCING_TERMS_FAILED },
          })
          const errorMessage = error.message ? error.message : 'Error getting financing terms.'
          this.errors.push(errorMessage)
        }
      }
    },
    async getProductCreditTerms() {
      const { response, errors } = await this.tsClient.getProductCreditTerms(
        this.financingProductSKU,
        this.country
      )
      if (errors.length > 0) {
        this.errors.push(errors)
      }
      this.creditTerms = response ? response.creditTermOptions : []
    },
    async initializeComponent() {
      await this.getProductCreditTerms()

      await this.combineFinanceTerms()

      this.isLoading = false
    },
    async combineFinanceTerms() {
      const financeTerms = await this.getFinanceTerms()
      if (financeTerms) {
        this.financeTerms.push(financeTerms)
      }

      // If a discount is provided call FS Terms endpoint with the discount amount
      if (this.discount) {
        const discountedFinanceTerms = await this.getFinanceTerms(this.discount)
        if (discountedFinanceTerms) {
          this.discountedFinanceTerms.push(discountedFinanceTerms)
        }
      }

      this.combinedFinanceTerms = this.financeTerms.map((term, index) => ({
        term,
        discountedTerm: this.discountedFinanceTerms && this.discountedFinanceTerms[index],
      }))
    },
  },
  computed: {
    tsClient() {
      return new TermsServiceClient(this.env)
    },
    fsClient() {
      return new FinanceClient(this.env)
    },
    downPaymentTerm() {
      return this.country == countryCodesEnum.AUSTRALIA ? 'deposit' : 'down payment'
    },
  },
  watch: {
    discount: async function() {
      this.financeTerms = []
      this.discountedFinanceTerms = []
      await this.combineFinanceTerms()
    }
  }
}
</script>
<style></style>
