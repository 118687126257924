import BaseApi from "./api-config";
import { datadogLogs } from "@datadog/browser-logs";
import { errorCodes, ENVIRONMENTS } from "@/utils/constants";

export class TermsServiceClient {
  tsApi;

  result = {
    response: null,
    errors: []
  };

  #apiConfig = {
    [ENVIRONMENTS.QA]: {
      host: 'https://gateway.platform-qa.smiledirect.services/terms-service',
      version: 'v1',
    },
    [ENVIRONMENTS.STAGE]: {
      host: 'https://gateway.platform-stage.smiledirect.services/terms-service',
      version: 'v1',
    },
    [ENVIRONMENTS.PROD]: {
      host: 'https://gateway.platform-prod.smiledirect.services/terms-service',
      version: 'v1',
    },
  }

  constructor(env) {
    this.config = this.#apiConfig[env];
    if (!this.config) throw new Error('Invalid environment configuration');

    this.tsApi = new BaseApi(this.config.host, this.config.version);
  }
  async getProductCreditTerms(financingProductSKU, country) {
    try {
      const path = `terms/${financingProductSKU}?country=${country}`
      const response = await this.tsApi.callApi('get', path)
      this.result.response = response.data
    } catch (error) {
      datadogLogs.logger.error(
        `Error: ${error}`,
        { errorDetails: { errorCode: errorCodes.RETRIEVING_PRODUCT_CREDIT_TERMS_FAILED } },
      );
      this.result.errors.push(error.response.data.message)
    }
    return this.result
  }
}
