/* eslint-disable */
import Vue from 'vue';
import ComponentLibrary from 'sdc-component-library';
import SmilePayUI from '@/components/SmilePayUI.vue';
import SubSmilePay from '@/components/SubSmilePay.vue';

import 'sdc-component-library/dist/sdc.css';

Vue.use(ComponentLibrary);

export default {SmilePayUI};
export {SmilePayUI, SubSmilePay};
