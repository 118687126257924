import axios from 'axios';

export default class BaseApi {
  host;
  version;

  constructor(host, version) {
    this.host = host;
    this.version = version;
  }

  async callApi(method, path, data = null, options = {}) {
    const fullPath = `${this.host}/${this.version}/${path}`;

    const body = {
      method,
      url: fullPath,
      data,
      ...options,
    };

    return axios(body);
  }
}
