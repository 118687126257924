<template>
  <div
    id="sub-smilepay-container"
    data-orion-id="sub-smilepay-container"
    class="container"
  >
    <smile-media-card v-if="showComponent" data-orion-id="sub-smilepay-smile-card" class="sub-smilepay-smile-card">
      <template v-slot:card-content>
        <h4 id="syb-smilepay-label" class="title is-5 has-text-left">
          SmilePay<sup>TM</sup> Payment Plan
        </h4>

        <div style="margin-top:24px;" class="content is-normal">
          <div v-if="isDiscountApplied">
            <div class="is-flex is-justify-content-space-between bottom-padding regular-text">
              Regular monthly payment
              <br>
              <p class="has-text-black">{{ monthlyPaymentFormatted }}</p>
            </div>
            <div class="is-flex has-text-success is-justify-content-space-between bottom-padding">
              <p class="mb-0 has-text-left">Promo code applied ({{promoCode}})</p>
              <p>-{{ promoAmountFormatted }}</p>
            </div>
            <div class="is-flex is-justify-content-space-between blurple-padding blurple-border bold-black" style="position:relative;">
              <div class="blurple-div">Monthly payment after discount</div>
              <p class="mb-0">Monthly payment</p>
              <p>{{ discountedMonthlyPaymentFormatted }}</p>
            </div>
          </div>

          <div v-else>
            <div class="is-flex is-justify-content-space-between blurple-padding blurple-border" style="position:relative;">
              <div class="blurple-div">Amount due today</div>
              <p class="mb-0 regular-text">Deposit</p>
              <p>{{ downPaymentFormatted }}</p>
            </div>
            <div class="is-flex is-justify-content-space-between top-padding regular-text">
              Monthly payment
              <br>
              <p class="has-text-black">{{ monthlyPaymentFormatted }}</p>
            </div>
          </div>

          <div class="divider"></div>
          <ul class="component-list regular-text has-text-left">
            <li>
              Your {{ months }} monthly payments will begin after your aligners have shipped.
            </li>
            <li>
              Any promotional discount will reduce your monthly payments.
            </li>
          </ul>
        </div>
      </template>
    </smile-media-card>
  </div>
</template>

<script>

import {formatPrice} from "@/utils/formatter";

export default {
  name: 'SubSmilePay',
  props: {
    country: {
      required: true,
      type: String,
    },
    currency: {
      required: true,
      type: String,
    },
    promoCode: {
      required: false,
      type: String,
    },
    promoAmount: {
      required: false,
      type: Number,
    },
    monthlyPayment: {
      required: true,
      type: Number,
    },
    downPayment: {
      required: true,
      type: Number,
    },
    discountedMonthlyPayment: {
      required: false,
      type: Number,
    },
    months: {
      required: true,
      type: Number,
    }
  },
  data() {
    return {
      showComponent: false,
      isDiscountApplied: false,
    }
  },
  beforeMount() {
    this.showComponent =
      Number.isInteger(this.downPayment) &&
      Number.isInteger(this.monthlyPayment) &&
      Number.isInteger(this.months) &&
      (isNaN(this.discountedMonthlyPayment) || Number.isInteger(this.discountedMonthlyPayment)) &&
      (isNaN(this.promoAmount) || Number.isInteger(this.promoAmount)) &&
      typeof this.currency !== 'undefined' &&
      typeof this.country !== 'undefined'

    this.isDiscountApplied = this.promoCode && this.promoAmount && this.discountedMonthlyPayment
  },
  computed: {
    downPaymentFormatted() {
      return formatPrice(this.downPayment / 100, this.currency)
    },
    monthlyPaymentFormatted() {
      return formatPrice(this.monthlyPayment / 100, this.currency)
    },
    discountedMonthlyPaymentFormatted() {
      return formatPrice(this.discountedMonthlyPayment / 100, this.currency, true)
    },
    promoAmountFormatted() {
      return formatPrice(this.promoAmount / 100, this.currency)
    }
  },
}
</script>


<style scoped>
  .divider {
    border-top: 2px solid;
    border-color: #e0e0e0;
    height: 0.1rem;
    margin: 1.25rem 0;
  }

  .component-list {
    list-style-type: disc;
    margin-left: 20px;
    font-size: 14px;
  }

  .blurple-border {
    border: 1px solid #5007FF;
    border-radius: 4px;
  }

  .blurple-div {
    position: absolute;
    width: 200px;
    height: 20px;
    left: calc(50% - 117px/2 + 0.5px);
    top: calc(50%/2 - 25px);
    background: #5700FF;
    color: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .bold-black {
    color: #000000;
    font-weight: 600;
  }

  .bottom-padding {
    padding: 0 12px 20px 12px;
  }

  .top-padding {
    padding: 20px 12px 0 12px;
  }

  .blurple-padding {
    padding: 20px 12px;
  }

  .regular-text {
    color: #666666;
  }

</style>
