<template>
  <div
    :data-orion-id="`smilepay-ui-card-content-${optionId}`"
    :class="[
      'smilepay-ui-card-content',
      'card card-content-padding',
      'justify-center',
      'mt-5',
      'has-border',
      {
        'payment-selected': selected,
      },
    ]"
  >
    <smile-media-card
      data-orion-id="smilepay-ui-smile-card"
      class="smilepay-ui-smile-card"
      @click.native="selectedPaymentPlan"
    >
      <template v-slot:card-content>
        <div class="is-flex is-justify-content-space-between">
          <smile-radio
            name="payment-option-radio"
            class="smilepay-ui-payment-option-paymentType pl-0 title title-slim is-6"
            :data-orion-id="`smilepay-ui-payment-option-type-${optionId}`"
            :native-value="true"
            :value="selected"
          >
            {{ title }}<sup v-if="financeTerm">TM</sup>
          </smile-radio>
          <div
            class="smilepay-ui-payment-option-price title title-slim is-6"
            :data-orion-id="`smilepay-ui-payment-option-price-${optionId}`"
          >
            <div
              v-if="isDiscountValid"
              :data-orion-id="`smilepay-ui-payment-option-discount-price-${optionId}`"
            >
              <b>{{ discountedPriceText }}</b>
            </div>
            <div
              :class="{ 'discount-text': isDiscountValid }"
              :data-orion-id="`smilepay-ui-payment-option-price-${optionId}`"
            >
              {{ priceText }}
            </div>
          </div>
        </div>
        <div class="content">
          <ul>
            <li
              class="smilepay-ui-payment-option-description has-text-left"
              v-for="description in paymentDescriptions"
              :key="description"
              :data-orion-id="`smilepay-ui-payment-option-description-${optionId}`"
              v-html="description"
            ></li>
          </ul>
        </div>
      </template>
    </smile-media-card>
  </div>
</template>

<script>
import { SmileMediaCard, SmileRadio } from 'sdc-component-library'
import { countryCodesEnum, paymentOptionsTitlesEnum } from '@/utils/constants'
import { formatEventPrice, formatPrice } from '@/utils/formatter'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'PaymentOption',
  components: { SmileMediaCard, SmileRadio },
  props: {
    title: {
      required: true,
      type: String,
    },
    price: {
      required: true,
      type: Number,
    },
    productSKU: {
      required: true,
      type: String,
    },
    currency: {
      required: true,
      type: String,
    },
    country: {
      required: true,
      type: String,
    },
    discount: {
      required: false,
      type: Number,
    },
    paymentDescriptions: {
      required: true,
      type: Array,
    },
    targetDomain: {
      type: String,
      default: '/',
    },
    financeTerm: {
      type: Object,
    },
    discountedFinanceTerm: {
      type: Object,
    },
    region: String,
    selected: Boolean,
  },
  data() {
    return {
      annualPercentageRate: 10,
      businessEntity: this.country,
      paymentPlan: this.title.replace(/ /g, '').toUpperCase(),
      countryCodesEnum,
      paymentOptionsTitlesEnum,
    }
  },
  mounted() {
    this.$emit("mounted");
  },
  methods: {
    preparePaymentPlanData() {
      const isDiscountApplied = this.discount && this.discountedFinanceTerm
      const financeTerm = this.discountedFinanceTerm || this.financeTerm
      const creditProduct = financeTerm?.credit_product

      const eventMessage = {
        eventType: 'SelectedPaymentPlanEvent',
        eventId: uuidv4(),
        amount: isDiscountApplied
          ? this.price - this.discount
          : this.price,
        currency: this.currency,
        businessEntity: this.country,
        paymentPlan: this.paymentPlan,
        months: this.financeTerm ? financeTerm.months : undefined,
        downPayment: this.financeTerm
          ? formatEventPrice(creditProduct.down_payment * 100)
          : undefined,
        totalPayment: this.financeTerm
          ? formatEventPrice(financeTerm.total_payment * 100)
          : undefined,
        monthlyPayment: this.financeTerm
          ? formatEventPrice(financeTerm.monthly_payment * 100)
          : undefined,
        creditProductId: this.financeTerm ? creditProduct.product_id : undefined,
        financingProvider: this.financeTerm ? creditProduct.provider_name : undefined,
        supportedPaymentMethods: this.financeTerm
          ? creditProduct.supported_payment_methods
          : undefined,
        originalPrices: isDiscountApplied ? {
          amount: this.price,
          totalPayment: this.financeTerm
            ? formatEventPrice(this.financeTerm.total_payment * 100)
            : undefined,
          monthlyPayment: this.financeTerm
            ? formatEventPrice(this.financeTerm.monthly_payment * 100)
            : undefined,
        } : undefined,
      }

      return eventMessage;
    },
    selectedPaymentPlan() {
      // [this.$vnode.key] Key value passed from the main component
      this.$emit('payment-option-selected', this.$vnode.key)

      if (window.parent) {
        const data = this.preparePaymentPlanData()
        const eventMessage = {
          eventType: 'SelectedPaymentPlanEvent',
          eventId: uuidv4(),
          ...data,
        };
        window.parent.postMessage(eventMessage, this.targetDomain)
      }
    },
  },
  computed: {
    priceFormatted() {
      return formatPrice(this.price / 100, this.currency)
    },
    monthlyPriceFormatted() {
      return formatPrice(this.financeTerm?.monthly_payment, this.currency, true)
    },
    dicountedPriceFormatted() {
      return formatPrice((this.price - this.discount) / 100, this.currency)
    },
    discountedMonthlyPriceFormatted() {
      return formatPrice(this.discountedFinanceTerm?.monthly_payment, this.currency, true)
    },
    optionId() {
      return this.$vnode.key || 1
    },
    isDiscountValid() {
      return this.discount && this.discountedFinanceTerm
    },
    priceText() {
      switch (this.title) {
        case paymentOptionsTitlesEnum.SINGLE_PAY:
          return this.priceFormatted
        case paymentOptionsTitlesEnum.SMILE_PAY:
          return `${this.monthlyPriceFormatted}/month`
        default:
          return ''
      }
    },
    discountedPriceText() {
      switch (this.title) {
        case paymentOptionsTitlesEnum.SINGLE_PAY:
          return this.dicountedPriceFormatted
        case paymentOptionsTitlesEnum.SMILE_PAY:
          return `${this.discountedMonthlyPriceFormatted}/month`
        default:
          return ''
      }
    },
  },
  watch: {
    discountedFinanceTerm: {
      handler: function() {
        if (!this.selected) return;

        if (window.parent) {
          const data = this.preparePaymentPlanData()
          const eventMessage = {
            eventType: 'SelectedPaymentPlanEvent',
            eventId: uuidv4(),
            ...data,
          };
          window.parent.postMessage(eventMessage, this.targetDomain)
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.title.title-slim {
  font-weight: normal !important;
}
.payment-selected {
  border-color: #5700ff;
}
.smilepay-ui-smile-card {
  cursor: pointer;
}
.smilepay-ui-card-content {
  font-size: 14px;
}
.discount-text {
  text-decoration: line-through;
  color: gray;
  font-size: 17px;
}
</style>
